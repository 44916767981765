import styled, { keyframes } from "styled-components";
import {
    merge,
    bounceInDown,
    bounceInRight,
    bounceOutRight,
    bounceInLeft,
    bounceOutLeft,
} from "react-animations";
import { bounceIn } from "react-animations";

export const BounceInDownAndRightInfiniteAnimation = styled.div`
    animation: 1s alternate-reverse infinite ${keyframes`${merge(bounceInDown, bounceInRight)}`};
`;

export const BounceInRightAnimation = styled.div`
    animation: 1s ${keyframes`${bounceInRight}`};
`;

export const BounceOutRightAnimation = styled.div`
    animation: 1s forwards ${keyframes`${bounceOutRight}`};
`;

export const BounceInDownAndLeftInfiniteAnimation = styled.div`
    animation: 1s alternate-reverse infinite ${keyframes`${merge(bounceInDown, bounceInLeft)}`};
`;

export const BounceInDownAndLeftAnimation = styled.div`
    animation: 1s ${keyframes`${merge(bounceInDown, bounceInLeft)}`};
`;

export const BounceInLeftAnimation = styled.div`
    animation: 1s ${keyframes`${bounceInLeft}`};
`;

export const BounceOutLeftAnimation = styled.div`
    animation: 1s forwards ${keyframes`${bounceOutLeft}`};
`;

export const BounceInDownAnimation = styled.div`
    animation: 1s ${keyframes`${bounceInDown}`};
`;

export const BounceInAnimation = styled.div`
    animation: 1s ${keyframes`${bounceIn}`};
`;
