import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    gameStarted: false,
    playerChoice: null,
    computerChoice: null,
    winner: null,
    playerScore: 0,
    computerScore: 0,
};

export const gameSlice = createSlice({
    name: "game",
    initialState,
    reducers: {
        setGameStarted: (state, action) => {
            state.gameStarted = action.payload;
        },
        setPlayerChoice: (state, action) => {
            state.playerChoice = action.payload;
        },
        setComputerChoice: (state, action) => {
            state.computerChoice = action.payload;
        },
        setWinner: (state, action) => {
            state.winner = action.payload;
        },
        setPlayerScore: (state, action) => {
            state.playerScore = action.payload;
        },
        setComputerScore: (state, action) => {
            state.computerScore = action.payload;
        },
    },
});

export const {
    setGameStarted,
    setPlayerChoice,
    setComputerChoice,
    setWinner,
    setPlayerScore,
    setComputerScore,
} = gameSlice.actions;

export const getWinner = (playerChoice, computerChoice) => (dispatch, getState) => {
    let winner;

    if (playerChoice === computerChoice) return "draw";
    if (playerChoice === "rock") winner = computerChoice === "paper" ? "computer" : "player";
    if (playerChoice === "paper") winner = computerChoice === "scissors" ? "computer" : "player";
    if (playerChoice === "scissors") winner = computerChoice === "rock" ? "computer" : "player";

    dispatch(setWinner(winner));
    if (winner === "player") dispatch(setPlayerScore(getState().game.playerScore + 1));
    if (winner === "computer") dispatch(setComputerScore(getState().game.computerScore + 1));
};

export const resetGame = () => (dispatch, _) => {
    dispatch(setGameStarted(false));
    dispatch(setPlayerChoice(null));
    dispatch(setComputerChoice(null));
    dispatch(setWinner(null));
};

export default gameSlice.reducer;
