import { Link } from "react-router-dom";
import { FaReact } from "react-icons/fa";
import { ImClock } from "react-icons/im";
import { useEffect, useState } from "react";
import { SiRedux, SiTailwindcss } from "react-icons/si";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import {
    BounceInAnimation,
    BounceInDownAnimation,
    BounceInLeftAnimation,
} from "../utils/animations";

import file from "../CHANGELOG.md";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";

import "github-markdown-css";

function About() {
    const [markdown, setMarkdown] = useState("");

    useEffect(() => {
        fetch(file)
            .then((response) => response.text())
            .then((text) => setMarkdown(text));
    }, []);

    return (
        <div className="min-h-screen overflow-x-hidden">
            <Link to="/">
                <BsFillArrowLeftCircleFill className="z-50 m-6 md:m-12 absolute text-4xl text-zinc-300 hover:text-blue-300" />
            </Link>
            <div className="flex flex-col pt-5 max-w-4xl mx-auto">
                <BounceInDownAnimation>
                    <h1 className="w-full text-center text-3xl md:text-5xl xl:text-6xl font-bold text-zinc-300">
                        Project Details
                    </h1>
                    <h2 className="w-full text-center text-2xl md:text-4xl xl:text-5xl font-semibold text-zinc-300 my-6 md:my-12">
                        Rock, Paper, Scissors
                    </h2>
                </BounceInDownAnimation>
                <BounceInLeftAnimation>
                    <div className="flex my-3 md:my-6 p-5 w-full">
                        <div className="flex flex-col w-full text-center">
                            <FaReact className="text-5xl text-blue-500 mx-auto" />
                            <span className="text-lg font-semibold text-white mt-2">React</span>
                        </div>
                        <div className="flex flex-col w-full text-center">
                            <SiRedux className="text-5xl text-purple-500 mx-auto" />
                            <span className="text-lg font-semibold text-white mt-2">Redux</span>
                        </div>
                        <div className="flex flex-col w-full text-center">
                            <SiTailwindcss className="text-5xl text-blue-400 mx-auto" />
                            <span className="text-lg font-semibold text-white mt-2">
                                Tailwind CSS
                            </span>
                        </div>
                        <div className="flex flex-col w-full text-center">
                            <ImClock className="text-5xl text-zinc-400 mx-auto" />
                            <span className="text-lg font-semibold text-white mt-2">&#60; 12H</span>
                        </div>
                    </div>
                    <div className="p-4 mx-4 text-zinc-300 bg-zinc-900 rounded-lg shadow">
                        <div className="h-3 text-3xl text-left">“</div>
                        <p className="px-4 text-md text-center">
                            Rock, Paper, Scissors is a simple game where you can play against the
                            computer. The game is played with a set of 3 objects: Rock, Paper and
                            Scissors. The player who wins the game is the one who has the highest
                            score.
                            <br />
                            <br />I have created this game as a way to practice my animation skills
                            in React and to learn more about React. I have also learned about Redux
                            and Tailwind CSS to make the game look and feel great. I hope you enjoy
                            the game!
                        </p>
                        <div className="h-3 text-3xl text-right">”</div>
                    </div>
                </BounceInLeftAnimation>
                <BounceInAnimation>
                    <div className="p-6 my-4 md:my-8 mx-4 text-gray-800 bg-zinc-900 rounded-lg shadow">
                        <div className="markdown-body bg-transparent">
                            <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />
                        </div>
                    </div>
                </BounceInAnimation>
            </div>
        </div>
    );
}

export default About;
