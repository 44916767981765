import { useDispatch, useSelector } from "react-redux";
import { setGameStarted, setPlayerChoice } from "../features/gameSlice";
import { FaHandRock, FaHandPaper, FaHandScissors } from "react-icons/fa";

function OptionButton(props) {
    const { disabled, name } = props;

    const playerChoice = useSelector((state) => state.game.playerChoice);
    const dispatch = useDispatch();

    const playGame = (playerChoice) => {
        dispatch(setPlayerChoice(playerChoice));
        dispatch(setGameStarted(true));
    };

    const renderIcon = (option) => {
        switch (option) {
            case "rock":
                return (
                    <FaHandRock className="text-2xl md:text-3xl rotate-90 text-accent mx-auto mb-2" />
                );
            case "paper":
                return (
                    <FaHandPaper className="text-2xl md:text-3xl rotate-90 text-accent mx-auto mb-2" />
                );
            case "scissors":
                return (
                    <FaHandScissors className="text-2xl md:text-3xl scale-x-[-1] text-accent mx-auto mb-2" />
                );
            default:
                return null;
        }
    };

    return (
        <button
            className={`h-20 w-1/3 md:w-1/4 btn btn-outline btn-info btn-lg ${
                disabled && "btn btn-lg btn-disabled btn-error"
            } ${playerChoice === name && "loading"} mx-2`}
            onClick={() => playGame(name)}>
            {playerChoice !== name && (
                <div className="flex flex-col">
                    {renderIcon(name)} {name}
                </div>
            )}
        </button>
    );
}

export default OptionButton;
