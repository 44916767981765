import { useEffect } from "react";
import { setComputerChoice } from "../features/gameSlice";
import { useSelector, useDispatch } from "react-redux";
import { FaHandHolding, FaHandRock, FaHandPaper, FaHandScissors } from "react-icons/fa";
import {
    BounceInDownAndRightInfiniteAnimation,
    BounceInRightAnimation,
    BounceOutRightAnimation,
} from "../utils/animations";

function Computer(props) {
    const { isLoading, color } = props;

    const gameStarted = useSelector((state) => state.game.gameStarted);
    const computerChoice = useSelector((state) => state.game.computerChoice);
    const playerChoice = useSelector((state) => state.game.playerChoice);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!gameStarted || !playerChoice) return;

        dispatch(setComputerChoice(["rock", "paper", "scissors"][Math.floor(Math.random() * 3)]));
    }, [gameStarted, playerChoice, dispatch]);

    const renderRandomHand = (option) => {
        switch (option) {
            case "rock":
                return (
                    <FaHandRock
                        className={`text-8xl md:text-9xl -rotate-90 scale-x-[-1] mx-auto ${color}`}
                    />
                );
            case "paper":
                return (
                    <FaHandPaper
                        className={`text-8xl md:text-9xl -rotate-90 scale-x-[-1] mx-auto ${color}`}
                    />
                );
            case "scissors":
                return <FaHandScissors className={`text-8xl md:text-9xl mx-auto ${color}`} />;
            default:
                return null;
        }
    };

    return (
        <div>
            <h2 className="text-3xl md:text-4xl font-semibold text-center mb-24">AI</h2>
            {isLoading && (
                <>
                    <BounceInDownAndRightInfiniteAnimation>
                        <FaHandRock className="text-8xl -rotate-90 scale-x-[-1] mx-auto" />
                    </BounceInDownAndRightInfiniteAnimation>
                    <BounceInRightAnimation>
                        <FaHandHolding className="text-9xl -mt-16 scale-x-[-1] mx-auto" />
                    </BounceInRightAnimation>
                </>
            )}
            {!isLoading && (
                <>
                    <BounceInRightAnimation>
                        {renderRandomHand(computerChoice)}
                    </BounceInRightAnimation>
                    <BounceOutRightAnimation>
                        <FaHandHolding className="text-9xl -mt-16 scale-x-[-1] mx-auto" />
                    </BounceOutRightAnimation>
                </>
            )}
        </div>
    );
}

export default Computer;
