import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { getWinner, resetGame } from "./features/gameSlice";
import { useDispatch, useSelector } from "react-redux";

import Player from "./components/Player";
import Computer from "./components/Computer";
import OptionButton from "./components/OptionButton";

function App() {
    const [isLoading, setIsLoading] = useState(true);

    const gameStarted = useSelector((state) => state.game.gameStarted);
    const playerChoice = useSelector((state) => state.game.playerChoice);
    const computerChoice = useSelector((state) => state.game.computerChoice);
    const winner = useSelector((state) => state.game.winner);
    const playerScore = useSelector((state) => state.game.playerScore);
    const computerScore = useSelector((state) => state.game.computerScore);
    const dispatch = useDispatch();

    useEffect(() => {
        if (playerChoice === null || computerChoice === null) return;

        setTimeout(() => {
            setIsLoading(false);
            dispatch(getWinner(playerChoice, computerChoice));
        }, 2000);
    }, [playerChoice, computerChoice, dispatch]);

    useEffect(() => {
        if (!computerChoice || !playerChoice) return;

        setTimeout(() => {
            setIsLoading(true);
            dispatch(resetGame());
        }, 5000);
    }, [computerChoice, playerChoice, dispatch]);

    const getColor = (player) => {
        if (!winner) return "text-warning";
        if (winner === player) return "text-success";
        return "text-error";
    };

    return (
        <>
            <div class="alert shadow-lg">
                <div>
                    <FaInfoCircle className="w-8 h-8" />
                    <span>Want to know more regarding this project?</span>
                </div>
                <div class="flex-none">
                    <Link to="/about" class="btn btn-sm btn-accent">
                        Read More
                    </Link>
                </div>
            </div>
            <div className="p-5 h-full w-full overflow-x-hidden">
                <h1 className="w-full text-center text-3xl md:text-6xl xl:text-6xl font-bold text-zinc-300">
                    Rock, Paper, Scissors
                </h1>
                <div className="md:mt-48">
                    <div className="hidden md:flex justify-evenly mx-auto my-24">
                        <Player isLoading={isLoading} color={getColor("player")} />
                        <h3 className="text-4xl font-extrabold">
                            <span className="text-accent">{playerScore}</span> -{" "}
                            <span className="text-accent">{computerScore}</span>
                        </h3>
                        <Computer isLoading={isLoading} color={getColor("computer")} />
                    </div>
                    <div className="flex md:hidden justify-evenly mx-auto my-12">
                        <div className="flex flex-col justify-center text-center">
                            <h3 className="text-4xl font-extrabold text-accent">{playerScore}</h3>
                            <Player isLoading={isLoading} color={getColor("player")} />
                        </div>
                        <div className="flex flex-col justify-center text-center">
                            <h3 className="text-4xl font-extrabold text-accent">{computerScore}</h3>
                            <Computer isLoading={isLoading} color={getColor("computer")} />
                        </div>
                    </div>
                    <div className="flex justify-center mx-auto max-w-6xl">
                        <OptionButton name="rock" disabled={gameStarted} />
                        <OptionButton name="paper" disabled={gameStarted} />
                        <OptionButton name="scissors" disabled={gameStarted} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
