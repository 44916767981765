import { useSelector } from "react-redux";
import { FaHandHolding, FaHandRock, FaHandPaper, FaHandScissors } from "react-icons/fa";
import {
    BounceInDownAndLeftInfiniteAnimation,
    BounceInDownAndLeftAnimation,
    BounceInLeftAnimation,
    BounceOutLeftAnimation,
} from "../utils/animations";

function Player(props) {
    const { isLoading, color } = props;

    const playerChoice = useSelector((state) => state.game.playerChoice);

    const renderHand = (option) => {
        switch (option) {
            case "rock":
                return <FaHandRock className={`text-8xl md:text-9xl rotate-90 mx-auto ${color}`} />;
            case "paper":
                return (
                    <FaHandPaper className={`text-8xl md:text-9xl rotate-90 mx-auto ${color}`} />
                );
            case "scissors":
                return (
                    <FaHandScissors
                        className={`text-8xl md:text-9xl scale-x-[-1] mx-auto ${color}`}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div>
            <h2 className="text-3xl md:text-4xl font-semibold text-center mb-24">Player</h2>
            {isLoading && (
                <>
                    <BounceInDownAndLeftInfiniteAnimation>
                        <FaHandRock className="text-8xl rotate-90 mx-auto" />
                    </BounceInDownAndLeftInfiniteAnimation>
                    <BounceInLeftAnimation>
                        <FaHandHolding className="text-9xl -mt-16 mx-auto" />
                    </BounceInLeftAnimation>
                </>
            )}
            {!isLoading && (
                <>
                    <BounceInDownAndLeftAnimation>
                        {renderHand(playerChoice)}
                    </BounceInDownAndLeftAnimation>
                    <BounceOutLeftAnimation>
                        <FaHandHolding className="text-9xl -mt-16" />
                    </BounceOutLeftAnimation>
                </>
            )}
        </div>
    );
}

export default Player;
